<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Suppliers") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <b-container v-if="!localSuppliers.length">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <h4 class="text-muted">{{ $t("No Suppliers Found") }}</h4>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else>
        <div>
          <datatable ref="table" :items-provider="localSuppliers" :total="total" :fields="fields"
            :options.sync="tableOptions" :per-page-options="perPageOptions">
            <template #[`cell.vat`]="{ item }">
              <div v-if="item.vat">
                <div v-for="[country, vat] in Object.entries(item.vat)" :key="vat" class="h-100 text-uppercase mt-2">
                  <CountryFlag :country-iso="country" class="px-1"></CountryFlag>
                  {{ vat }}
                </div>
              </div>
            </template>
            <template #[`cell.name`]="{ item }">
              <div class="d-flex align-items-center">
                <avatar :avatar-image="item.logo" :avatar-text="item.name[0]"></avatar>
                <div class="ml-3 font-weight-bolder">{{ item.name }}</div>
              </div>
            </template>
            <template #[`cell.date_from`]="{ item }">
              <div class="d-flex align-items-center">
                <div class="ml-3">
                  {{ item.date_start ? DateService.format(item.date_start, dateFormat) : "" }}
                </div>
              </div>
            </template>
            <template #[`cell.date_to`]="{ item }">
              <div class="d-flex align-items-center">
                <div class="ml-3">
                  {{ item.date_end ? DateService.format(item.date_end, dateFormat) : "" }}</div>
              </div>
            </template>
            <template v-for="customCol in customColumns" v-slot:[`cell.${customCol.key}`]="{ item }">
              <slot :name="`cell.${customCol.key}`" :item="item"> </slot>
            </template>
          </datatable>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DateService from "@/core/services/date.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import icons from "@/core/config/icons.js";
import CountryFlag from "@/view/components/CountryFlag.vue";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { perPageOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    CountryFlag,
    Datatable,
  },

  props: {
    suppliers: {
      type: Array,
      required: true,
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DateService: DateService,
      localSuppliers: [],
      icons,
      isLoading: false,
      total: 0,
      perPage: 10,
      perPageOptions: perPageOptions,
      tableOptions: {
        "sort-by": "name",
        "sort-desc": true,
        "per-page": 10,
        "current-page": 1,
        "no-local-sorting": true,
      },
      customColumns: []
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),
    fields() {
      let f = [
        { label: this.$t("Name"), key: "name", sortable: true, class: "align-middle" },
        { label: this.$t("Vat"), key: "vat", sortable: false, class: "align-middle" },
        {
          label: this.$t("Date from"),
          key: "date_from",
          sortable: false,
          class: "align-middle",
        },
        {
          label: this.$t("Date to"),
          key: "date_to",
          sortable: false,
          class: "align-middle",
        },
      ];

      if (this.customColumns.length) {
        f = [...f, ...this.customColumns];
      }

      return f;
    },
  },

  created() {
    this.resetAllSuppliers();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments")},
      { title: this.$t("Suppliers") },
    ]);
  },

  methods: {
    resetAllSuppliers() {
      this.localSuppliers = _.cloneDeep(this.suppliers);
    },
    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>

