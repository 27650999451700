var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Suppliers")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [!_vm.localSuppliers.length ? _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h4', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t("No Suppliers Found")))])])], 1)], 1) : _c('b-container', [_c('div', [_c('datatable', {
    ref: "table",
    attrs: {
      "items-provider": _vm.localSuppliers,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.vat",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.vat ? _c('div', _vm._l(Object.entries(item.vat), function (_ref2) {
          var country = _ref2[0],
            vat = _ref2[1];
          return _c('div', {
            key: vat,
            staticClass: "h-100 text-uppercase mt-2"
          }, [_c('CountryFlag', {
            staticClass: "px-1",
            attrs: {
              "country-iso": country
            }
          }), _vm._v(" " + _vm._s(vat) + " ")], 1);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('avatar', {
          attrs: {
            "avatar-image": item.logo,
            "avatar-text": item.name[0]
          }
        }), _c('div', {
          staticClass: "ml-3 font-weight-bolder"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }, {
      key: "cell.date_from",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "ml-3"
        }, [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_start, _vm.dateFormat) : "") + " ")])])];
      }
    }, {
      key: "cell.date_to",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "ml-3"
        }, [_vm._v(" " + _vm._s(item.date_end ? _vm.DateService.format(item.date_end, _vm.dateFormat) : ""))])])];
      }
    }, _vm._l(_vm.customColumns, function (customCol) {
      return {
        key: "cell.".concat(customCol.key),
        fn: function fn(_ref6) {
          var item = _ref6.item;
          return [_vm._t("cell.".concat(customCol.key), null, {
            "item": item
          })];
        }
      };
    })], null, true)
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }